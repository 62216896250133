import React from 'react';
import styled from 'styled-components';

import Image from '../Image/Image';

import Discord from '../../assets/img/discord.svg';
import Tiktok from '../../assets/img//tiktok.svg';
import Youtube from '../../assets/img/youtube.svg';

export const Social = styled.div`
  display: flex;
  gap: 15px;

  & img {
    padding: 0;
    cursor: pointer;
    filter: brightness(100%);

    @media (max-width: 620px) {
      width: 20px;
      height: 20px;
      padding: 6px 0;
    }
  }

  & img:hover {
    filter: brightness(50%);
  }
`;

const socialList = [
  {
    src: Discord,
    alt: 'Discord',
    href: 'https://discord.com/invite/42M2b3vJFS',
  },
  {
    src: Tiktok,
    alt: 'Tik-Tok',
    href: 'https://www.tiktok.com/@nanax.community?lang=ru-RU',
  },
  {
    src: Youtube,
    alt: 'YouTube',
    href: 'https://www.youtube.com/channel/UCaYOnBhFWvfXZzTHLqZDO1A',
  },
];

export const SocialImages = () => {
  return (
    <>
      <Social>
        {socialList.map(({ src, alt, href }) => {
          return (
            <a href={href} target='_blank' rel='noreferrer' key={alt}>
              <Image src={src} alt={alt} />
            </a>
          );
        })}
      </Social>
    </>
  );
};

export default SocialImages;
