import styled from 'styled-components';
import { NavLink as Link } from 'react-router-dom';

export const Container = styled.div`
  width: 100%;
  max-width: 1190px;
  margin: 0 auto;
  overflow: hidden;

  @media (max-width: 1242px) {
    width: 100%;
    max-width: 992px;
  }
  @media (max-width: 1030px) {
    width: 100%;
    max-width: 768px;
  }
  @media (max-width: 810px) {
    width: 100%;
    max-width: 576px;
  }
  @media (max-width: 620px) {
    width: 100%;
    max-width: 375px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0;

  @media (max-width: 1242px) {
    padding: 25px 0;
  }
  @media (max-width: 1030px) {
    padding: 15px 0;
  }
  @media (max-width: 810px) {
    padding: 10px 0;
  }
  @media (max-width: 620px) {
    padding: 0;
  }

  #burger {
    display: none;
  }

  [for='burger'] {
    display: none;
  }

  [for='burger'] > span,
  [for='burger'] > span::before,
  [for='burger'] > span::after {
    position: absolute;
    width: 9px;
    height: 1px;
    background-color: ${(props) => props.theme.colors.white};
    transition-duration: 0.25s;
    right: 0;
  }

  [for='burger'] > span::before {
    content: '';
    top: -6px;
    width: 20px;
  }

  [for='burger'] > span::after {
    content: '';
    top: 6px;
    width: 20px;
  }

  #burger:checked ~ [for='burger'] > span::after {
    content: '';
    top: -6px;
    width: 20px;
  }

  [for='burger'] {
    display: none;
    cursor: pointer;
    position: absolute;
    width: 30px;
    height: 16px;
    text-align: center;
    right: 44px;
    top: 42%;

    @media (max-width: 1242px) {
      display: block;
    }

    @media (max-width: 1030px) {
      top: 34%;
      right: 38px;
    }
    @media (max-width: 620px) {
      top: 38%;
      right: 36px;
    }
  }

  #burger:checked ~ [for='burger'] {
    top: 46%;

    @media (max-width: 1030px) {
      top: 36%;
    }
    @media (max-width: 810px) {
      top: 38%;
    }
  }

  [for='burger'] > span {
    top: 50%;
  }

  aside {
    display: none;
  }

  #burger:checked ~ [for='burger'] > span {
    transform: rotate(45deg);
  }
  #burger:checked ~ [for='burger'] > span::before {
    top: 0;
    transform: rotate(0);
  }
  #burger:checked ~ [for='burger'] > span::after {
    top: 0;
    transform: rotate(90deg);
  }

  #burger:checked + label + nav {
    display: none;

    @media (max-width: 1242px) {
      display: block;
      position: absolute;
      background: #191919;
      top: 70%;
      right: 4.5%;
      width: 205px;
      border: 1px solid ${(props) => props.theme.colors.lightgray};
      padding: 20px;
      z-index: 100;

      a {
        margin: 0;
        padding: 0 0 5px 0;
        &:last-child {
          padding: 0;
        }
      }
    }

    @media (max-width: 1030px) {
      top: 60%;
      right: 5.5%;
    }
    @media (max-width: 810px) {
      right: 7.5%;
    }
    @media (max-width: 620px) {
      top: 70%;
      right: 11.5%;
    }
  }

  #burger:checked + label ~ aside {
    display: block;
    position: absolute;
    background: #191919;
    top: 70%;
    right: 3.5%;
    width: 205px;
    border: 1px solid ${(props) => props.theme.colors.gray};
    padding: 20px;
    z-index: 100;

    a {
      margin: 0;
      padding: 0 0 5px 0;
      color: ${(props) => props.theme.colors.lightgray};
      &: hover {
        color: ${(props) => props.theme.colors.white};
      }
      &:last-child {
        padding: 0;
      }
    }

    @media (max-width: 1242px) {
      display: none;
    }
  }
`;

export const LogoImg = styled.img`
  padding: 0;
  width: auto;
  // height: unset;
  margin: 0;
  cursor: pointer;

  @media (max-width: 810px) {
    width: 120px;
    padding: 20px 0 0 0;
  }

  @media (max-width: 620px) {
    padding: 10px 0 0 0;
    width: 90px;
  }
`;

export const Nav = styled.nav`
  display: flex;
  margin: 0 auto;

  @media (max-width: 1242px) {
    margin: 0 auto;
    display: none;
  }
`;

export const LinkStyled = styled(Link)`
  display: block;
  font-weight: ${(props) => props.theme.fontWieight.light};
  color: ${(props) => props.theme.colors.lightgray};
  text-decoration: none;
  font-size: 20px;
  line-height: 30px;
  margin: 40px 25px 40px 0;
  padding: 0 0 5px 0;
  transition: color 0.25s;

  &:nth-child(7) {
    display: block;
  }
  &:nth-child(8) {
    display: block;
  }

  &:hover {
    color: ${(props) => props.theme.colors.white};
  }

  @media (min-width: 1242px) {
    color: ${(props) => props.theme.colors.white};
    &:nth-child(7) {
      display: none;
    }
    &:nth-child(8) {
      display: none;
    }
  }
`;

// export const Aside = styled.aside``;

export const BoxBTN = styled.div`
  position: absolute;
  right: 0;
  top: 36%;

  @media (max-width: 1030px) {
    top: 27%;
  }

  // @media (max-width: 620px) {
  //   top: 24%;
  // }
`;

export const Footer = styled.footer`
  padding: 40px 0 0 0;
  border-top: 1px solid #646464;

  @media (max-width: 620px) {
    padding: 30px 0 0 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Inter', sans-serif;

  &:first-child {
    padding: 0 0 20px 0;
  }

  &:last-child {
    padding: 0 0 36px 0;
    font-size: 14px;
    line-height: 17px;
    color: ${(props) => props.theme.colors.gray};
  }

  @media (max-width: 1030px) {
    align-items: flex-start;
  }

  @media (max-width: 620px) {
    &:last-child {
      display: block;
      margin: -70px 0 0 0;
    }
  }
`;

export const Copy = styled.div`
  margin: 0;

  @media (max-width: 620px) {
    margin: 0 0 10px 0;
  }
`;

export const WrapperFooter = styled.footer`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-basis: 50%;
  font-family: 'Inter', sans-serif;

  & p {
    font-size: 16px;
    line-height: 24px;
    cursor: pointer;
    transition: color 0.25s;
  }

  & p:hover {
    color: ${(props) => props.theme.colors.lightgray};
  }

  @media (max-width: 1030px) {
    flex-basis: 65%;
  }

  @media (max-width: 620px) {
    display: block;
    flex-basis: 40%;
  }
`;

export const LinkDefault = styled.a`
  color: inherit;
  text-decoration: none;
  display: block;
  margin: 0 0 16px 0;
  font-size: 16px;
  line-height: 145%;

  @media (max-width: 620px) {
    margin: 0 0 10px 0;
    p {
      font-size: 14px;
      line-height: 17px;
    }
  }
`;

export const FirstBlock = styled.div`
  display: block;

  @media (max-width: 1030px) {
    display: none;
  }
`;

export const BlockHidden = styled.div`
  display: none;
  margin: 0;

  @media (max-width: 1030px) {
    display: block;
    margin: 0 0 40px 0;
  }
`;

export const FooterImg = styled.img`
  width: auto;
  height: auto;
  margin: 0;
  cursor: pointer;

  @media (max-width: 810px) {
    width: 120px;
  }

  @media (max-width: 620px) {
    padding: 0;
    width: 90px;
  }
`;

export const Social = styled.div`
  margin: 0;

  @media (max-width: 620px) {
    margin: 40px 0 0 0;
  }
`;