import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SocialImages } from '../SocialImages/SocialImages';
import { Button } from '../Button/Button';

import Logo from '../../assets/img/Logo.svg';
import LogoFooter from '../../assets/img/logoFooter.svg';

import { LANGUAGE } from '../../constants/lang';

import {
  LinkStyled,
  Header,
  Container,
  Nav,
  Footer,
  WrapperFooter,
  Wrapper,
  BoxBTN,
  LinkDefault,
  LogoImg,
  FirstBlock,
  FooterImg,
  BlockHidden,
  Social,
  Copy,
} from './LayoutStyled';

export const Layout = () => {
  const [language, setLanguage] = useState(
    LANGUAGE[navigator.language] || 'RU'
  );
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();

  const handleChangeLocation = () => {
    setLanguage(language.toLowerCase() === 'ru' ? 'EN' : 'RU');
    i18n.changeLanguage(language.toLowerCase() === 'ru' ? 'en' : 'ru');
  };

  const goToHome = () => navigate('/');

  const goToUp = () => window.scrollTo(0, -200);

  return (
    <Container>
      <Header>
        <LogoImg
          src={Logo}
          alt='Logo'
          onClick={goToHome}
          width='140px'
          height='62px'
        />

        <input type='checkbox' id='burger' />
        <label htmlFor='burger' className='menu__btn'>
          <span></span>
        </label>

        <Nav>
          <LinkStyled to='rating'>{t('Rating')}</LinkStyled>
          <LinkStyled to='teams'>{t('Teams')}</LinkStyled>
          <LinkStyled to='schedule'>{t('Schedule')}</LinkStyled>
          <LinkStyled to='results'>{t('Tournament table')}</LinkStyled>
          <LinkStyled to='rules'>{t('Rules')}</LinkStyled>
          <LinkStyled to='streams'>{t('Streams')}</LinkStyled>
          <LinkStyled to='positions'>{t('positions on map!')}</LinkStyled>
        </Nav>

        <div style={{ width: '80px' }}></div>

        <BoxBTN>
          <Button onClick={handleChangeLocation} className='btn-location'>
            {language}
          </Button>
        </BoxBTN>
      </Header>

      <Outlet />

      <Footer>
        <Wrapper>
          <div>
            <BlockHidden>
              <LinkDefault href='#yak2'>
                <p>{t('About Us')}</p>
              </LinkDefault>
              <LinkDefault href='/rules' target='_blank' rel='noreferrer'>
                <p>{t('Rules')}</p>
              </LinkDefault>
              <LinkDefault href='/streams' target='_blank' rel='noreferrer'>
                <p>{t('Streams')}</p>
              </LinkDefault>
              <LinkDefault href='/positions' target='_blank' rel='noreferrer'>
                <p>{t('Positions')}</p>
              </LinkDefault>
            </BlockHidden>

            <FooterImg src={LogoFooter} alt='Logo Footer' onClick={goToUp} />
          </div>

          <WrapperFooter>
            <FirstBlock>
              <LinkDefault href='#yak2'>
                <p>{t('About Us')}</p>
              </LinkDefault>
              <LinkDefault href='/rules' target='_blank' rel='noreferrer'>
                <p>{t('Rules')}</p>
              </LinkDefault>
              <LinkDefault href='/streams' target='_blank' rel='noreferrer'>
                <p>{t('Streams')}</p>
              </LinkDefault>
              <LinkDefault href='/positions' target='_blank' rel='noreferrer'>
                <p>{t('Positions')}</p>
              </LinkDefault>
            </FirstBlock>

            <div>
              <LinkDefault href='/rating' target='_blank' rel='noreferrer'>
                <p>{t('Rating')}</p>
              </LinkDefault>
              <LinkDefault href='/teams' target='_blank' rel='noreferrer'>
                <p>{t('Teams')}</p>
              </LinkDefault>
              <LinkDefault href='/results' target='_blank' rel='noreferrer'>
                <p>{t('Tournament table')}</p>
              </LinkDefault>
              <LinkDefault href='/schedule' target='_blank' rel='noreferrer'>
                <p>{t('Schedule')}</p>
              </LinkDefault>
            </div>

            <Social>
              <LinkDefault
                href='https://play-cs.com/'
                target='_blank'
                rel='noreferrer'
              >
                <p>play-cs.com</p>
              </LinkDefault>
              <LinkDefault
                href='mailto:nanax.comm@gmail.com'
                target='_blank'
                rel='noreferrer'
                style={{ paddingBottom: '28px' }}
              >
                <p>nanax.comm@gmail.com</p>
              </LinkDefault>

              <SocialImages />
            </Social>
          </WrapperFooter>
        </Wrapper>

        <Wrapper>
          <Copy>© {new Date().getFullYear()} Nanax Community</Copy>
          <div>
            <LinkDefault href='https://berndsgn.com/'>berndsgn.com</LinkDefault>
          </div>
        </Wrapper>
      </Footer>
    </Container>
  );
};

export default Layout;
