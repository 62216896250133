import { Suspense, lazy } from 'react';
import styled from 'styled-components';
import { Routes, Route } from 'react-router-dom';
import { Layout } from './components/Layout/Layout';

const Main = lazy(() => import('./modules/Main/Main'));
const Teams = lazy(() => import('./modules/Teams/Teams'));
const Results = lazy(() => import('./modules/Results/Results'));
const Streams = lazy(() => import('./modules/Streams/Streams'));
const Rules = lazy(() => import('./modules/Rules/Rules'));
const Rating = lazy(() => import('./modules/Rating/Rating'));
const Schedule = lazy(() => import('./modules/Schedule/Schedule'));
// const Positions = lazy(() => import('./modules/Positions'));
const MockPage = lazy(() => import('./modules/MockPage/MockPage'));

const H1 = styled.h1`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const App = () => {
  return (
    <Suspense fallback={<H1>Loading...</H1>}>
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Main />} />
          <Route path='rating' element={<Rating />} />
          <Route path='teams' element={<Teams />} />
          <Route path='results' element={<Results />} />
          <Route path='schedule' element={<Schedule />} />
          <Route path='rules' element={<Rules />} />
          <Route path='streams' element={<Streams />} />
          {/* <Route path='positions' element={<MockPage />} /> */}
          <Route path='*' element={<MockPage />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default App;
