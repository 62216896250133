import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import { ThemeProvider } from 'styled-components';
import './i18n';

const theme = {
  colors: {
    white: '#FFFFFF',
    lightgray: '#868686',
    gray: '#646464',
    lightblack: '#2B2B29',
    black: '#191919',
    boldBlack: '#151515',
    red: '#FF0000'
  },
  fontWieight: {
    extralight: 200,
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600,
    bold: 700
  }
};

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
